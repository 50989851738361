import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"
import PartnerStructure from 'components/Structure/partner'
import logo from './partnerlogo.png'

import MainHeader from "components/header"
import "components/layout.scss"

const headerNavigation = [
  {text: "Partner Dashboard", link: "https://partners.kindful.com/partner_admins/sign_in", target: "_blank"},
  {text: "Sitemap", link: "/partner/sitemap"},
  {text: "Contact Support", link: "mailto:partnersupport@kindful.com"}
]

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SitePartnerQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
      <MainHeader logo={logo} logoLink="./" nav={headerNavigation}/>
      <PartnerStructure>
        <div>
          <main>{children}</main>
        </div>
      </PartnerStructure>
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
